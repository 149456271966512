import React from "react";
import withLayout from "../components/withLayout";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
  PageContainer,
  PageHeader,
  WhiteContentBox,
  HR
} from "../components/common";
import linkedin_icon from "../images/linkedin-box.svg";
import SocialMedia from "../components/SocialMedia";
import Img from "gatsby-image";
import { media } from "../css/theme";

const Photo = styled.div`
  width: 100% !important;
  flex-shrink: 0;
  border-radius: 8px;
  height: 200px !important;
  ${props => props.theme.card1};
  ${media.small_tablet`
    height: 250px !important;
    width: 250px !important;
  `};
`;

const Card = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  overflow: visible !important;
  margin: 16px auto;
  ${props => props.theme.card1};
  h2 {
    margin-top: 0;
  }
  section {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
  ${media.small_tablet`
    height: 250px;
    flex-direction: row;
    background-color: transparent;
    align-items: center;
    box-shadow: none;
    margin: 32px;
    max-width: initial;

    section {
      padding: 32px;
    }
  `};
`;

const ProfileCard = ({ name, desc, linkedIn, fixed }) => (
  <Card>
    <Photo as={Img} fixed={fixed} />
    <section>
      <h2>{name}</h2>
      <HR />
      <p>{desc}</p>
      <SocialMedia to={linkedIn} icon={linkedin_icon} alt="linkedin profile" />
    </section>
  </Card>
);

// overriding ImageCard styles

const About = ({ content, colin_img, brendan_img }) => (
  <PageContainer>
    <PageHeader>
      <h1>{content.title}</h1>
      <h4>{content.subtitle}</h4>
    </PageHeader>
    <WhiteContentBox>
      <h2 style={{ marginTop: 0 }}>{content.blurb_title}</h2>
      <HR />
      <section dangerouslySetInnerHTML={{ __html: content.blurb_content }} />
    </WhiteContentBox>
    {/* <ProfileCard
      name={content.name_1}
      desc={content.name_1_desc}
      fixed={brendan_img.childImageSharp.fixed}
      alt="photo of brendan"
      linkedIn={content.name_1_linkedin}
    /> */}
  </PageContainer>
);

export default withLayout(About);

export const query = graphql`
  query getAbout($Locale: String!) {
    colin_img: file(name: { eq: "colin" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    brendan_img: file(name: { eq: "brendan" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    content: allAirtable(
      filter: { table: { eq: "About" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...static
    }
    layout: allAirtable(
      filter: { table: { eq: "Layout" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...layout
    }
  }
`;
